import React from 'react';
import { Grid, makeStyles, Tab } from '@material-ui/core';

import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Edit2 as EditIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams, routeCreator } from 'services/routing';
import { ProfessionalTableRowData } from 'services/tables/professionalsTable';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';
import ProfessionalPatientsOverview from 'pages/patients/ProfessionalPatientsOverview';
import ProfessionalStaffOverview from 'pages/staff/ProfessionalStaffOverview';

import ProfessionalDevicesOverview from '../ProfessionalDevices/ProfessionalDevicesOverview';
import ProfessionalEducationModuleDetails from '../ProfessionalEducationModuleDetails/ProfessionalEducationModuleDetails';
import ProfessionalProfessionalGroupsOverview from '../ProfessionalProfessionalGroups/ProfessionalProfessionalGroupsOverview';
import ProfessionalProfessionalInvitesOverview from '../ProfessionalProfessionalInvites/ProfessionalProfessionalInvitesOverview';
import CardProfessionalAccessPeriods from './components/CardProfessionalAccessPeriods';
import CardProfessionalDetails from './components/CardProfessionalDetails';
import CardProfessionalLogs from './components/CardProfessionalLogs';
import CardProfessionalSubscriptions from './components/CardProfessionalSubscriptions';

const useStyles = makeStyles((_) => ({
  panel: {
    display: 'contents',
  },
}));

type Props = RouteComponentProps<
  ProfessionalRouteParams,
  any,
  ProfessionalTableRowData | undefined
>;

enum TabValues {
  Overview = '1',
  Patients = '2',
  Groups = '3',
  Devices = '4',
  Staff = '5',
  Invites = '6',
  Education = '7',
}

const ProfessionalDetail: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { professionalId } = props.match.params;
  const [tabValue, setTabValue] = React.useState<TabValues>(TabValues.Overview);

  const request = useFetch<ProfessionalTableRowData | null>(`/professionals/${professionalId}`, {
    includes: ['usersCount', 'organization'],
  });

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    setTabValue(newValue);
  };

  const professional = request.data as ProfessionalTableRowData;

  const actions = (
    <>
      <LinkButton
        translationKey='edit'
        to={routeCreator.EditProfessional(professionalId)}
        state={professional}
        color='primary'
        variant='contained'
        startIcon={<EditIcon size={20} />}
      >
        Edit
      </LinkButton>
    </>
  );

  return (
    <DetailPage title={professional.name} actions={actions}>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} centered variant='fullWidth'>
          <Tab label={getLocalizedString('overview')} value={TabValues.Overview} />
          <Tab label={getLocalizedString('patients')} value={TabValues.Patients} />
          <Tab label={getLocalizedString('groups')} value={TabValues.Groups} />
          <Tab label={getLocalizedString('devices')} value={TabValues.Devices} />
          <Tab label={getLocalizedString('staff')} value={TabValues.Staff} />
          <Tab label={getLocalizedString('professional_invites')} value={TabValues.Invites} />
          <Tab label={getLocalizedString('education')} value={TabValues.Education} />
        </TabList>

        <TabPanel value={TabValues.Overview} className={classes.panel}>
          <DetailPage title={getLocalizedString('overview')}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} xl={3}>
                <CardProfessionalDetails professional={professional} />
              </Grid>
              <Grid item xs={12} md={8} lg={8} xl={9}>
                <CardProfessionalSubscriptions professionalId={professionalId} />
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <CardProfessionalAccessPeriods professionalId={professionalId} />
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <CardProfessionalLogs professionalId={professionalId} />
              </Grid>
            </Grid>
          </DetailPage>
        </TabPanel>

        <TabPanel value={TabValues.Patients} className={classes.panel}>
          <ProfessionalPatientsOverview {...props} />
        </TabPanel>

        <TabPanel value={TabValues.Groups} className={classes.panel}>
          <ProfessionalProfessionalGroupsOverview {...props} />
        </TabPanel>

        <TabPanel value={TabValues.Devices} className={classes.panel}>
          <ProfessionalDevicesOverview {...props} />
        </TabPanel>

        <TabPanel value={TabValues.Staff} className={classes.panel}>
          <ProfessionalStaffOverview {...props} />
        </TabPanel>

        <TabPanel value={TabValues.Invites} className={classes.panel}>
          <ProfessionalProfessionalInvitesOverview {...props} />
        </TabPanel>

        <TabPanel value={TabValues.Education} className={classes.panel}>
          <ProfessionalEducationModuleDetails {...props} />
        </TabPanel>
      </TabContext>
    </DetailPage>
  );
};

export default ProfessionalDetail;
