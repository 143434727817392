import { AvailableLanguage } from 'services/localization/localization';

import { IEntityTimestamps } from './core';
import { IPlayerAccountStatistics } from './playerAccount';
import { IProfessional } from './professional';

export interface IUser extends IEntityTimestamps {
  id: number;
  email: string;

  first_name?: string;
  middle_name: string;
  last_name?: string;
  /**
   * The full name.
   */
  name?: string;

  language: AvailableLanguage;

  has_password: boolean;

  /**
   * Google Analytics client ID.
   */
  ga_cid: string;

  /**
   * AppsFlyer customer ID.
   */
  appsflyer_cuid: string;

  /**
   * Indicates that this user account is not claimed by an actual user. If this
   * is true, this user account was created by a professional or via an
   * external system (like a payment provider).
   */
  unclaimed: boolean;

  /**
   * Indicates that this user is excluded from reports.
   */
  exclude_reports: boolean;

  /**
   * The origin of the user's registration.
   */
  registration_origin: RegistrationOrigin;

  email_verified_at: string | null;
  last_login_at: string | null;
  last_activity_at: string | null;
}

/**
 * Checks if an e-mail address is a placeholder e-mail address for Reducept users.
 * This is used primarily for patients of professionals that are being managed
 * by a professional and where the professional did not provide an e-mail address
 * for this patient.
 *
 * An example of a placeholder e-mail address is:
 *   - player-23c894276a2c5a16470e6a31f4618d73@reducept.com
 *   - player-fc2022c89b61c76bbef978f1370660bf@reducept.com
 *   - player-6a83c731660fcc9f14e1ce0b62d45eb9@reducept.com
 *
 * @param {string} email
 *   The e-mail address to test.
 *
 * @return {boolean}
 *   True if the given e-mail is a placeholder address.
 */
export const isPlaceholderEmail = (email: string): boolean => /^player-.*reducept.com$/.test(email);

export const isFullyManagedByProfessional = (user: IUser): boolean =>
  user.email_verified_at === null || isPlaceholderEmail(user.email) || user.unclaimed;

export interface IUserPainScore {
  player_account_id: number;
  professional: IProfessional | null;
  statistics: IPlayerAccountStatistics;
  chapters: {
    chapter: string;
    started_at: number;
    pain_score: number;
    pain_point_x: number;
    pain_point_y: number;
    pain_point_z: number;
  }[];
}

export interface IUserWellbeingScore {
  player_account_id: number;
  professional: IProfessional | null;
  statistics: IPlayerAccountStatistics;
  chapters: {
    chapter: string;
    started_at: number;
    wellbeing_score: number;
  }[];
}

export interface IChangeUserPasswordPayload {
  password: string;
}

export enum RegistrationOrigin {
  UNKNOWN = 0,
  WEBSITE = 1,
  B2B2C = 2,
  STAFF = 3,
}

const registrationOriginDictionary = [
  { key: RegistrationOrigin.UNKNOWN, value: 'unknown' },
  { key: RegistrationOrigin.WEBSITE, value: 'website' },
  { key: RegistrationOrigin.B2B2C, value: 'b2b2c' },
  { key: RegistrationOrigin.STAFF, value: 'staff' },
];

export const getTranslatableRegistrationOriginName = (origin: RegistrationOrigin) => {
  return registrationOriginDictionary[origin]
    ? registrationOriginDictionary[origin].value
    : origin.toString();
};
