import React from 'react';

import { GridOptions } from '@material-ui/data-grid';
import PerfectScrollbar from 'react-perfect-scrollbar';

import DataGrid, { Props as DataGridProps } from 'components/mui/DataGrid/DataGrid';
import DataGridToolbar from 'components/mui/DataGrid/DataGridToolbar';
import { IPaginated } from 'core/backend';
import { UseFetchFilterOption, UseFetchHook, UseFetchSortOption } from 'hooks/useFetch';
import { defaultPaginationOptions } from 'services/pagination';

interface IProps {
  request: UseFetchHook<IPaginated<any>, never>;
}

type Props = IProps & Omit<DataGridProps, 'rows'>;

const PaginatedServerDataGrid: React.FC<Props> = ({ request, ...dataGridProps }) => {
  const initialFilter = React.useRef(request.options.filtering ?? []);
  /**
   * Event handler for when the pagination size or page has changed.
   *
   * @param {GridPageChangeParams} params
   *   The change parameters containing the current state of pagination.
   */
  const handlePaginationChange: GridOptions['onPageChange'] | GridOptions['onPageSizeChange'] = (
    params,
  ) => {
    request.setOptions({
      pagination: {
        number: params.page + 1,
        size: params.pageSize,
      },
    });
  };

  /**
   * Event handler for when a user sorts a column.
   *
   * @param {GridSortModelParams} params
   *   The change parameters containing the sorting model.
   */
  const handleSortModelChange: GridOptions['onSortModelChange'] = (params) => {
    const sortingValues = params.sortModel.map<UseFetchSortOption>((sortItem) => ({
      column: sortItem.field,
      direction: sortItem.sort ?? 'asc',
    }));

    request.setOptions({
      pagination: defaultPaginationOptions,
      sorting: sortingValues,
    });
  };

  /**
   * Event handler for when a user tries to filter a column.
   *
   * It's not possible to filter multiple columns when using Material-UI's
   * default DataGrid. If we would want to filter multiple columns in the
   * future, we should have to upgrade to XGrid
   *
   * @link https://material-ui.com/components/data-grid/filtering/#multi-column-filtering
   *
   * @param {GridFilterModelParams} params
   *   The change parameters containing the filter parameters.
   */
  const handleFilterModelChange: GridOptions['onFilterModelChange'] = (params) => {
    const { items } = params.filterModel;

    const filteringValues = items
      .filter((filterItem) => typeof filterItem.value === 'string' && filterItem.value.length > 0)
      .map<UseFetchFilterOption>((filterItem) => ({
        column: filterItem.columnField as string,
        value: filterItem.value as string,
      }));

    // The filtering values can be empty when a user just pressed the filter button,
    // in this situation the filter value for a column is still empty. Skip this.
    if (filteringValues.length === 0 && typeof request.options.filtering === 'undefined') {
      return;
    }

    request.setOptions({
      pagination: defaultPaginationOptions,
      filtering: [...initialFilter.current, ...filteringValues],
    });
  };

  return (
    <PerfectScrollbar>
      <DataGrid
        rows={request.data?.data || []}
        loading={request.loading}
        page={(request.data?.meta.current_page ?? defaultPaginationOptions.number) - 1}
        pageSize={request.data?.meta.per_page ?? defaultPaginationOptions.size}
        rowCount={request.data?.meta.total || 0}
        onPageChange={handlePaginationChange}
        onPageSizeChange={handlePaginationChange}
        paginationMode='server'
        onSortModelChange={handleSortModelChange}
        sortingMode='server'
        onFilterModelChange={handleFilterModelChange}
        filterMode='server'
        filterModel={undefined}
        components={{
          Toolbar: DataGridToolbar,
        }}
        {...dataGridProps}
      />
    </PerfectScrollbar>
  );
};

export default PaginatedServerDataGrid;
