import React from 'react';

import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Tv as TvIcon } from 'react-feather';

import LuxonDate from 'components/LuxonDate';
import ListItemText from 'components/mui/ListItemText';
import { IPaginated } from 'core/backend';
import { IEducationLog } from 'core/education';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

interface IProps {
  professionalId: number | string;
}

const CardProfessionalEducationLogCount: React.FC<IProps> = ({ professionalId }) => {
  const { getLocalizedString } = useLocalization();

  const request = useFetch<IPaginated<IEducationLog>>(
    `/professionals/${professionalId}/education/logs`,
  );

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const educationLogs = request.data?.data;

  const lastUsed = educationLogs.sort((logA, logB) => (logA.date > logB.date ? -1 : 1));

  return (
    <Card>
      <CardHeader
        avatar={<TvIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('education_module_usage')}
      />
      <Divider />
      <CardContent>
        <Box height={455} position='relative'>
          <List dense disablePadding>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('education_module_used_count')}
                secondary={educationLogs.length}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getLocalizedString('education_module_last_used')}
                secondary={
                  educationLogs.length === 0 ? (
                    '-'
                  ) : (
                    <LuxonDate date={lastUsed[0].date} fallback='-' />
                  )
                }
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardProfessionalEducationLogCount;
