import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { IPaginated, LoadedProfessionalUser, LoadedUserRole } from 'core/backend';
import { Professional } from 'core/professional';
import { IRole } from 'core/role';
import { ILoginPayload, ITokenResponse } from 'core/session';
import { IUser } from 'core/user';

import { backendURL } from './env';

/**
 * Axios HTTP Client for performing HTTP-requests.This instance contains
 * the user's authentication token which is included in the headers.
 */
const httpClient = axios.create({
  baseURL: backendURL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
});

/**
 * Uses the given (bearer) token in the Authentication header of our axios
 * HTTP client.
 *
 * @param token Authentication token to use.
 */
export const setToken = (token: string): void => {
  httpClient.defaults.headers.Authorization = `Bearer ${token}`;
};

/**
 * Delete's the Authorization header of our HTTP-client.
 */
export const unsetToken = (): void => {
  delete httpClient.defaults.headers.Authorization;
};

const login = (payload: ILoginPayload): Promise<AxiosResponse<ITokenResponse>> =>
  httpClient.post<ITokenResponse>('/login', payload);

const getUser = (userId: number | string): AxiosPromise<IUser> =>
  httpClient.get<IUser>(`/users/${userId}`);

const getUserProfessionals = (
  userId: number | string,
): AxiosPromise<IPaginated<Professional & LoadedProfessionalUser>> =>
  httpClient.get(`/users/${userId}/professionals`);

const getUserRoles = (userId: number | string): AxiosPromise<IPaginated<IRole & LoadedUserRole>> =>
  httpClient.get(`/users/${userId}/roles`);

export const apiMethods = {
  login,
  getUser,
  getUserProfessionals,
  getUserRoles,
};

export default httpClient;
