import React from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import DetailPage from 'components/DetailPage';
import { useLocalization } from 'services/localization/localization';
import { ProfessionalRouteParams } from 'services/routing';
import CardProfessionalEducationLogCount from '../ProfessionalDetail/components/CardProfessionalEducationLogCount';

const ProfessionalEducationModuleDetails: React.FC = () => {
  const { professionalId } = useParams<ProfessionalRouteParams>();

  const { getLocalizedString } = useLocalization();

  return (
    <DetailPage title={getLocalizedString('education')}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4} xl={3}>
          <CardProfessionalEducationLogCount professionalId={professionalId} />
        </Grid>
      </Grid>
    </DetailPage>
  );
};

export default ProfessionalEducationModuleDetails;
