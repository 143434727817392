import React from 'react';

import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ResponsiveScatterPlotCanvas, Datum } from '@nivo/scatterplot';
import { Wind as WindIcon } from 'react-feather';

import ProgressDivider from 'components/ProgressDivider';
import { IUser, IUserPainScore } from 'core/user';
import useProfessionalFetch from 'hooks/useProfessionalFetch';
import { useLocalization } from 'services/localization/localization';
import colors from 'theme/colors';

import { IDateRangeFilterProps } from './CardUserPainScores';

type CustomSerieDatum = {
  painScore: number;
} & Datum;

interface CustomSerie {
  id: string | number;
  data: CustomSerieDatum[];
}

type Props = {
  user: IUser;
} & Partial<IDateRangeFilterProps>;

const CardUserPainScoresImage: React.FC<Props> = (props) => {
  const request = useProfessionalFetch<IUserPainScore[]>({
    default: [
      `/users/${props.user.id}/pain-scores?startDate=${
        props.startDate !== null ? props.startDate?.toISODate() : ''
      }&endDate=${props.endDate !== null ? props.endDate?.toISODate() : ''}`,
      undefined,
      { defaultValue: [] },
    ],
    professional: (professionalId) => [
      `/professionals/${professionalId}/users/${props.user.id}/pain-scores?startDate=${
        props.startDate !== null ? props.startDate?.toISODate() : ''
      }&endDate=${props.endDate != null ? props.endDate?.toISODate() : ''}`,
      undefined,
      { defaultValue: [] },
    ],
  });

  const { getLocalizedString } = useLocalization();

  const series: CustomSerie[] = [];

  request.data!.forEach((dataset) => {
    const serie: CustomSerie = {
      id:
        dataset.professional === null
          ? `${getLocalizedString('personal')} (${dataset.player_account_id})`
          : dataset.professional.name,
      data: [],
    };

    for (let i = 0; i < dataset.chapters.length; i++) {
      if (dataset.chapters[i].pain_score === null || dataset.chapters[i].pain_score < 0) {
        continue;
      }

      const painScore = dataset.chapters[i].pain_score * 10;

      if (dataset.chapters[i].pain_point_x != null) {
        serie.data.push({
          painScore: painScore,
          x: dataset.chapters[i].pain_point_x,
          y: dataset.chapters[i].pain_point_y,
        });
      }
    }
    series.push(serie);
  });

  const customTheme = {
    textColor: 'transparent',
    axis: {
      ticks: {
        line: {
          stroke: 'transparent',
          strokeWidth: 0,
        },
      },
    },
  };

  const username = props.user.first_name || props.user.name;

  return (
    <Card>
      <CardHeader
        avatar={<WindIcon size={25} />}
        titleTypographyProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        title={getLocalizedString('pain_locations')}
        subheader={`${getLocalizedString('measured_pain_locations', {
          username: username ?? getLocalizedString('user'),
        })}`}
      />
      <ProgressDivider inProgress={request.loading} />
      <CardContent>
        <Box
          height={455}
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Box
            height={370}
            width={290}
            style={{
              backgroundImage: 'url(/body.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <ResponsiveScatterPlotCanvas
              data={series}
              theme={customTheme}
              colors={[
                colors.graph.primary,
                colors.graph.secondary,
                colors.graph.tertiary,
                colors.graph.quaternary,
              ]}
              blendMode='multiply'
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              xScale={{ type: 'linear', min: -1, max: 1 }}
              yScale={{ type: 'linear', min: -1, max: 1 }}
              enableGridX={false}
              enableGridY={false}
              useMesh={false}
              axisTop={null}
              axisRight={null}
              isInteractive={false}
              nodeSize={(node: Datum) => {
                // Difines min and max pixel size for painspots to display
                const minRange: number = 2;
                const maxRange: number = 10;
                let scaledPainScore: number = 0;

                let result = 0;
                series.forEach((item) => {
                  item.data.forEach((data) => {
                    if (data.x === node.x && data.y === node.y) {
                      // Scale the painscores between min and max pixelrange
                      scaledPainScore =
                        (maxRange - minRange) * ((data.painScore + 1) / (maxRange + 1)) + minRange;
                      result = scaledPainScore;
                    }
                  });
                });
                return result;
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardUserPainScoresImage;
